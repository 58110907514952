import { defineStore } from 'pinia'
import axios from 'axios'
import venueImage from '@/assets/images/place-2.png'
import video1 from '@/assets/videos/1.mp4'
import siteConfig from '@/config.json'
import { useCommon } from '@/hooks/useCommon'
import { useUserStore } from '@/stores/UserStore'

const { getImageUrl, getImageUrlBase } = useCommon()
const venue = {
  image: venueImage,
  title: 'Salmagundi Club',
  address: '12th Street, 47 Fifth Avenue, New York City, NY 10003',
  site: '#',
  googleMapLink: '#',
}

const videos = [
  {
    id: 'random-' + Math.floor(Math.random() * 10e20),
    src: video1,
    isPlayed: false,
  },
  {
    id: 'random-' + Math.floor(Math.random() * 10e20),
    src: video1,
    isPlayed: false,
  },
]

export const useExhibitionStore = defineStore('ExhibitionStore', {
  state: () => {
    return {
      exhibitions: [],
      exhibition: {},
      loadingExhibitions: false,
      loadingGalleryExhibitions: false,
      selectedFilter: 'Current',
      gallery: [],
      mediums: [],
      filter: {
        Current: true,
        Upcoming: false,
        Past: false,
      },
      jurorAssignments: [],
      loadingJurorData: false,
    }
  },

  getters: {
    exhibitionsByFilter() {
      const currentExhibitions = this.exhibitions.filter((item) => {
        const intakeOpen = new Date(item.intake_open).getTime()
        const intakeClose = new Date(item.intake_close).getTime()
        return Date.now() >= intakeOpen && Date.now() <= intakeClose
      })

      const upcomingExhibitions = this.exhibitions.filter((item) => {
        const intakeOpen = new Date(item.intake_open).getTime()
        return Date.now() < intakeOpen
      })

      const pastExhibitions = this.exhibitions.filter((item) => {
        const intakeClose = new Date(item.intake_close).getTime()
        return Date.now() > intakeClose
      })

      if (currentExhibitions.length) {
        this.filter.Current = true
      }

      if (upcomingExhibitions.length) {
        this.filter.Upcoming = true
      }

      if (pastExhibitions.length) {
        this.filter.Past = true
      }

      if (this.selectedFilter === 'Current') {
        return currentExhibitions
      }

      if (this.selectedFilter === 'Upcoming') {
        return upcomingExhibitions
      }

      if (this.selectedFilter === 'Past') {
        return pastExhibitions
      }

      return [...currentExhibitions, ...upcomingExhibitions, ...pastExhibitions]
    },
  },

  actions: {
    userInWhitelist(exhibition) {
      if (!exhibition.groupRestriction) return true
      return Object.values(useUserStore()?.groupIds)?.some(value => exhibition.groupRestriction == value.toString());
    },
    updateSelectedFilter(value) {
      this.selectedFilter = value
    },
    async fetchMediums() {
      if (!this.mediums || this.mediums.length === 0) {
        const { data } = await axios.get(`${siteConfig.apiBaseUrl}/exhibits/mediums`)
        this.mediums = data.results
      }
      return this.mediums
    },
    async fetchExhibitions() {
      this.loadingExhibitions = true

      try {
        const { data } = await axios.get(`${siteConfig.apiBaseUrl}/exhibits/allpublished`)

        if (!data.ok) {
          this.loadingExhibitions = false
          return
        }

        const formattedDate = (timestamp) => { return new Date(Number(timestamp)).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' }) }

        this.exhibitions = data?.results?.map((item) => {
          const start_date_fmt = formattedDate(item.dateOpen)
          const end_date_fmt = formattedDate(item.dateClose)
          const intake_open = formattedDate(item.intakeOpen)
          const intake_close = formattedDate(item.intakeClose)
          const reception_date = formattedDate(item.dateReception)

          return {
            ...item,
            id: item.exhibitionId,
            title: item.showTitle,
            start_date: item.dateOpen,
            end_date: item.dateClose,
            start_date_fmt,
            end_date_fmt,
            description: item.description,
            intake_open,
            intake_close,
            image: getImageUrl(item.imageURL),
            location: item.locationName,
            juried: item.juried,
            entry_nums: item.entryNums,
            num_entries: item.numEntries,
            reception_date,
            multipleEntriesSamePrice: item.entryNums > 1 && (!item.fee2 || item.fee2 === item.fee1),
          }
        })
        this.loadingExhibitions = false
      } catch (error) {
        console.log('fetchExhibitions error', error)
        this.loadingExhibitions = false
      }
    },

    async fetchExhibitionById(id) {
      this.loadingExhibitions = true
      try {

        if (this.exhibitions.length === 0) {
          await this.fetchExhibitions()
        }
        const exhibition = this.exhibitions.find((item) => item.id == id)

        exhibition.venue = venue
        exhibition.videos = videos

        this.exhibition = exhibition

        this.loadingExhibitions = false
        return exhibition
      } catch (error) {
        console.log('fetchExhibitionById error', error)
        this.loadingExhibitions = false
      }
    },

    async fetchGalleryExhibitionById(id) {
      this.loadingGalleryExhibitions = true
      try {
        const { data } = await axios.get(`${siteConfig.apiBaseUrl}/exhibits/exhibitentrylist/${id}`)

        if (!data.ok) return

        console.log('data eee', data.results)

        this.gallery = data.results.filter((item) => item.accepted).map((item) => {
          const shop_url = (item.itemId && item.publish && item.quantity > 0) ? `/shop/products/${item.itemId}?backTo=/exhibitions/${item.eventId}` : null
          let images = [item.image1Url, item.image2Url, item.image3Url, item.image4Url].filter(Boolean);
          if (images.length === 0) images.push("");
          return {
            ...item,
            images: images.map((image) => { return { "src": getImageUrl(image), "alt": item.title } }),
            artist_name: item.artistName,
            item_descr: item.itemDescr,
            shop_url: shop_url,
          }
        })

        this.loadingGalleryExhibitions = false
      } catch (error) {
        console.log('fetchGalleryExhibitionById error', error)
        this.loadingGalleryExhibitions = false
      }
    },

    async fetchJurorAssignments(personId) {
      if (!personId) return

      this.loadingJurorData = true
      try {
        const { data } = await axios.get(
          `${siteConfig.apiBaseUrl}/exhibits/jurorassignments/${personId}`
        )
        if (data.ok) {
          this.jurorAssignments = data.results
        }
      } catch (error) {
        console.error('Error fetching juror assignments:', error)
      } finally {
        this.loadingJurorData = false
      }
    },

    async fetchExhibitVotes(exhibitId, personId) {
      if (!personId) return

      this.loadingJurorData = true
      try {
        const { data } = await axios.get(
          `${siteConfig.apiBaseUrl}/exhibits/jurorvotesbyexhibitid/${personId}/${exhibitId}`
        )
        if (data.ok) {
          return await Promise.all(data.results.map(async vote => {
            return {
              ...vote,
              entry: await this.fetchExhibitEntry(vote.entryId)
            }
          }))
        }
      } catch (error) {
        console.error('Error fetching exhibit votes:', error)
      } finally {
        this.loadingJurorData = false
      }
    },

    async fetchExhibitEntry(entryId) {
      try {
        const { data } = await axios.get(`${siteConfig.apiBaseUrl}/exhibits/getexhibitentry/${entryId}`)
        if (data.ok) {
          let item = data.results
          let images = [item?.image1Url, item?.image2Url, item?.image3Url, item?.image4Url].filter(Boolean);
          if (images.length === 0) images.push("");
          return {
            ...item,
            images: images?.map((image) => { return { "src": getImageUrl(image), "alt": item.title } }),
          }
        }
      } catch (error) {
        console.error('Error fetching exhibit entry:', error)
      }
    },

    async submitVote(vote) {
      try {
        const { data } = await axios.post(
          `${siteConfig.apiBaseUrl}/exhibits/recordvote`,
          { jurorVoteWS: vote }
        )
        return data.ok
      } catch (error) {
        console.error('Error submitting vote:', error)
        return false
      }
    },

    setSorting(field) {
      if (this.sortBy === field) {
        this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc'
      } else {
        this.sortBy = field
        this.sortDirection = 'asc'
      }
    }
  },
})
