import { defineStore, acceptHMRUpdate } from 'pinia'
import siteConfig from '@/config.json'
import axios from 'axios'
import uniq from 'lodash/uniq'
import { useInstructorStore } from '@/stores/InstructorStore'
import { useUserStore } from '@/stores/UserStore'
import { getInstructorImageUrl, normalize } from './services/instructor'
import { usDateFormatToYYYYMMDD, timestampsToDateRange, getDateStandard } from '@/helpers/datetime'
import { capitalizeFirstLetter, cleanMarkup } from '@/helpers/text'
import { useCartStore } from '@/stores/CartStore'

const basename = (path) => {
  let parts = path.split('/')
  if (parts.length) {
    return parts[parts.length - 1]
  }
  return null
}

const getClassImageUrl = (imageUrl) => {
  let url
  if (imageUrl) {
    const imageName = basename(imageUrl)
    if (siteConfig.features?.cdn?.url) {
      // if (siteConfig.features.cdn.type === 'keycdn') {
      url = `${siteConfig.features.cdn.url}/library/system/${imageName}?width=986&height=740&fit=cover&enlarge=0&metadata=0`
    } else {
      url = `${siteConfig.legacyImagesBaseUrl}/system/${imageName}`
    }
  } else {
    url = `/sites/${siteConfig.key}/${siteConfig.assets.defaultClassImage || siteConfig.assets.logo}`
  }
  return url
}

const daySortPos = {
  Monday: 0,
  Tuesday: 1,
  Wednesday: 2,
  Thursday: 3,
  Friday: 4,
  Saturday: 5,
  Sunday: 6,
}

const noFilters = {
  semester: null,
  site: null,
  location: null,
  locationType: null,
  category: null,
  medium: null,
  program: null,
  // duration: null,
  level: null,
  instructor: null,
  day: null,
  dateRange: null,
  type: null,
  ageRange: null,
  availability: null,
  keywords: null,
}

export const useClassStore = defineStore('ClassStore', {
  state: () => {
    return {
      openClasses: [],
      openWorkshops: [],
      openClassProgramIds: [],
      programs: [],
      programIdsByName: {},
      semesters: [],
      mediums: [],
      programMediums: [],
      secondaryMediums: [],
      categories: [],
      levels: [],
      locations: [],
      sites: [],
      keywords: [],
      filters: noFilters,
      sortBy: null,
      searchQuery: null,
      hiddenFilters: { division: null },
      loadingClasses: false,
      loadingPrograms: false,
      loadingInstructors: false,
      classRegistrationCountsById: {},
      sessionRegistrationCountsById: {},
      roster: {},
      loadingRoster: false,
    }
  },

  getters: {
    loading() {
      const instructorStore = useInstructorStore()

      return this.loadingClasses || instructorStore.loadingInstructors || this.loadingPrograms
    },

    programsWithOpenClasses() {
      const programs = this.programs.filter((p) => this.openClassProgramIds.includes(p.eventId)).map((p) => p.title)

      if (siteConfig.features.programOrder) {
        const order = siteConfig.features.programOrder
        return programs.sort((a, b) => {
          if (order.indexOf(a) > order.indexOf(b)) return 1
          if (order.indexOf(a) < order.indexOf(b)) return -1
          return 0
        })
      } else {
        return programs
      }
    },

    programsById() {
      if (!this.programs.length) return {}

      if (!this.openClasses.length) return {}

      const programMap = {}

      this.programs.forEach((program) => {
        programMap[program.eventId] = program
      })

      return programMap
    },

    queryParams(state) {
      const params = {}
      Object.keys(this.filters).forEach((filter) => {
        if (this.filters[filter] !== null) {
          params[filter] = this.filters[filter]
        }
      })
      return params
    },

    search(state) {
      const searchFields = ['title', 'description', 'keywords']
      if (!this.searchQuery) return this.openClasses
      return this.openClasses.filter((c) => {
        return searchFields.some((field) => c[field]?.toLowerCase().includes(this.searchQuery.toLowerCase()))
      })
    },

    sortByOptions() {
      return [
        { text: 'Artist Name', value: 'instructorSummary' },
        { text: 'Title', value: 'shortTitle' },
        { text: 'Start Date', value: 'startDateStandard' },
      ]
    },
    sortByText() {
      const option = this.sortByOptions.find((option) => option.value === this.sortBy)

      return option ? option.text : ''
    },
    filteredList(state) {
      const instructorStore = useInstructorStore()

      let list = [...this.search]
      if (!siteConfig.features.showClassesAfterCutOffDate) {
        list = list.filter((c) => !c.isPastCutOffDate)
      }

      if (this.filters.dateRange) {
        const parts = this.filters.dateRange.split('_')
        const startDate = parts[0]

        if (parts.length === 2) {
          const endDate = parts[1]
          list = list.filter((c) => {
            if (c.daySales || siteConfig.features.classFilters?.showStartedClassesInDateRangeFilter) {
              const validDays = c.classDays.filter((d) => {
                return d.dateStandard >= startDate && d.dateStandard <= endDate
              })
              return validDays.length > 0
            } else {
              return c.startDateStandard >= startDate && c.startDateStandard <= endDate
            }
          })
        } else {
          list = list.filter((c) => {
            if (c.daySales || siteConfig.features.classFilters?.showStartedClassesInDateRangeFilter) {
              const validDays = c.classDays.filter((d) => {
                return d.dateStandard >= startDate
              })
              return validDays.length > 0
            } else {
              return c.startDateStandard >= startDate
            }
          })
        }
      }

      if (this.filters.ageRange && siteConfig.features.classFilters?.ageRangeFilters) {
        const rules = siteConfig.features.classFilters?.ageRangeFilters?.find((f) =>
          f.name.includes(this.filters.ageRange)
        )

        list = list.filter((item) => {
          if (!item.minAge || !item.maxAge) return true

          if (item.minAge > rules.maxAge) return false

          if (item.maxAge < rules.minAge) return false

          if (item.minAge <= rules.minAge && item.maxAge <= rules.maxAge) return true

          if (rules.minAge >= item.minAge) return true

          if (item.minAge >= rules.minAge || item.maxAge <= rules.maxAge) return true

          return false
        })
      }

      if (this.hiddenFilters.division) {
        // This filter is used to filter classes and workshops separately. The division is set in the router as 'class' or 'workshop', but sometimes we want to categorize multiple divisions as 'class' or 'workshop'
        const filters = siteConfig.features.separateClassesAndWorkshops?.[this.hiddenFilters.division] || [this.hiddenFilters.division]
        list = list.filter((c) => filters.includes(c.division))
      }

      if (this.filters.type) {
        list = list.filter((c) => this.filters.type.includes(c.division))
      }

      if (this.filters.semester) {
        list = list.filter((c) => this.filters.semester.includes(c.semesterName.trim()))
      }

      if (this.filters.site) {
        list = list.filter((c) => this.filters.site.includes(c.site))
      }

      if (this.filters.location) {
        list = list.filter((c) => this.filters.location.includes(c.location))
      }

      if (this.filters.locationType) {
        list = list.filter((c) => this.filters.locationType.includes(c.locationType))
      }

      if (this.filters.category) {
        list = list.filter((c) => this.filters.category.includes(c.department))
      }

      if (this.filters.program) {
        list = list.filter((c) => {
          return Array.isArray(this.filters.program) && this.filters.program.some(program =>
            this.programIdsByName[program] === c.eventId
          )
        })
      }

      if (this.filters.medium) {
        list = list.filter((c) => {
          return Array.isArray(this.filters.medium) && this.filters.medium.some(medium =>
            c.medium?.toLowerCase() === medium.toLowerCase() ||
            c.medium1?.toLowerCase() === medium.toLowerCase() ||
            c.medium2?.toLowerCase() === medium.toLowerCase()
          )
        })
      }

      if (this.filters.level) {
        list = list.filter((c) => this.filters.level.includes(c.classLevel))
      }

      if (this.filters.instructor) {
        list = list.filter((c) => {
          return Array.isArray(this.filters.instructor) && this.filters.instructor.some(instructor =>
            instructorStore.instructorsById[c.instructorId]?.fullName.trim() === instructor.trim() ||
            instructorStore.instructorsById[c.instructor2Id]?.fullName.trim() === instructor.trim()
          )
        })
      }

      if (this.filters.day) {
        list = list.filter((c) => {
          const validDays = c.classDays.filter((d) => this.filters.day.includes(d.classDay))
          return validDays.length > 0
        })
      }

      if (this.filters.availability) {
        list = list.filter((c) => {
          if (c.daySales) {
            return c.classDays.some((d) => d.limit - d.daysSold > 0)
          } else {
            return c.seatsAvailable > 0
          }
        })
      }

      if (this.filters.keywords) {
        list = list.filter((c) => {
          const keywords = c.keywords?.split(',')?.map((k) => k.trim())
          return keywords?.some((k) => this.filters.keywords.includes(k))
        })
      }

      if (this.sortBy) {
        list = list.sort((a, b) => {
          let valueA = a[this.sortBy]
          let valueB = b[this.sortBy]

          if (this.sortBy === 'startDateStandard') {
            valueA = a.classDays.sort((a, b) => a.date - b.date)[0]
            valueB = b.classDays.sort((a, b) => a.date - b.date)[0]
          }

          if (typeof valueA === 'string' && typeof valueB === 'string') {
            return valueA.trim().toLowerCase().localeCompare(valueB.trim().toLowerCase())
          } else if (typeof valueA === 'number' && typeof valueB === 'number') {
            return valueA - valueB
          } else {
            // Fallback to basic string comparison if types are different
            return String(valueA).localeCompare(String(valueB))
          }
        })
      }

      return list
    },
  },


  actions: {
    userInWhitelist(cls, defaultToTrue = true) {
      if (!cls.priorityGroups) return defaultToTrue
      const whiteListGroupIds = cls.priorityGroups.split(',').map((g) => g.trim()).filter((val) => val !== '').filter((g) => !isNaN(g))
      return Object.values(useUserStore()?.groupIds)?.some(value => whiteListGroupIds.includes(value.toString()));
    },
    async sendMessageClass(classId, message) {
      try {
        await axios.post(`${siteConfig.apiBaseUrl}/classes/${classId}/message`, { message })
      } catch (error) {
        console.log('error - sendMessageClass', error)
      }
    },

    updateMembershipPricing() {
      if (!siteConfig.features.dynamicMemberPrice) return
      const userStore = useUserStore()
      const membership = userStore?.membershipsInHousehold?.find((m) => m.member_id === userStore?.account?.personId)
      const membershipsInCart = useCartStore()?.membershipsInCart

      membership ? membership.categoryId = membership?.category_id : null // Standardize categoryId property name
      const highestDiscount = [...membershipsInCart, membership]?.filter(m => m !== null && m !== undefined)?.reduce((acc, m) => {
        const discount = siteConfig.memberships.find((mem) => mem.categoryId === m.categoryId)?.discounts?.['class'];

        // Check if discount is defined and has an 'amount' property
        if (discount && typeof discount.amount === 'number') {
          // Initialize acc with the current object if acc is not defined
          if (!acc) {
            return { ...m, discount: discount.amount };
          }

          // Update acc if discount is greater than acc's discount
          if (discount.amount > acc.discount) {
            return { ...m, discount: discount.amount };
          }
        }

        return acc;
      }, null);
      const discount = siteConfig.memberships.find((mem) => mem.categoryId === highestDiscount?.categoryId)?.discounts?.['class'] || { amount: 0 }
      this.openClasses.forEach((cls) => {
        let discountAmount = discount.type === 'percent' ? cls.costNon * (discount.amount / 100) : discount.amount
        cls.costMember = parseFloat((cls.costNon - discountAmount).toFixed(2))
        cls.costMemberStr = String(cls.costMember)
        cls.memberCostFmt = String(cls.costMember.toFixed(2))
        cls.memberSavings = parseFloat((cls.costNon - cls.costMember).toFixed(2))
      })
      useCartStore().items.filter((item) => item.type === 'class').forEach((cls) => {
        let discountAmount = discount.type === 'percent' ? cls.nonMemberCost * (discount.amount / 100) : discount.amount
        cls.memberCost = parseFloat((cls.nonMemberCost - discountAmount).toFixed(2))
      })
    },
    async fetchReportRoster(classDetails) {
      this.loadingRoster = true

      try {
        const response = await axios.get(
          `${siteConfig.apiBaseUrl}/reports/roster/${classDetails.catalogId}/data`
        )

        this.roster = response.data

        this.loadingRoster = false
      } catch (error) {
        this.loadingRoster = false
        console.log('error - fetchReportRoster', error)
      }
    },

    resetFilters() {
      Object.keys(this.filters).forEach((key) => {
        this.filters[key] = null
      })
    },

    applyFilters(filters) {
      Object.keys(filters).forEach((key) => {
        this.filters[key] = filters[key]
      })
    },

    fetchRegistrationCounts() {
      const url = `${siteConfig.apiBaseUrl}/reports/queries/class-registration-counts`

      axios
        .get(url)
        .then((response) => {
          const data = response.data
          if (!data.ok) return
          const classCounts = {}
          const sessionCounts = {}
          data.results.forEach((item) => {
            if (item.schedule_id) {
              sessionCounts[item.schedule_id] = item.registration_count
            } else {
              classCounts[item.catalog_id] = item.registration_count
            }
          })
          this.classRegistrationCountsById = classCounts
          this.sessionRegistrationCountsById = sessionCounts
        })
        .catch((error) => {
          console.log('Error fetching registration counts', error)
        })
    },

    fetchInstructors() {
      this.loadingInstructors = true

      const req = axios.get(`${siteConfig.apiBaseUrl}/classes/instructors`)

      req
        .then((response) => {
          let instructors = response.data

          const instructorsById = {}
          instructors.forEach((instructor) => {
            instructorsById[instructor.instructorId] = instructor
            instructor.imageUrl = getInstructorImageUrl(instructor.imageUrl)
          })
          this.instructorsById = instructorsById

          if (siteConfig.features.instructorExclusions && siteConfig.features.instructorExclusions.length) {
            instructors = instructors.filter(
              (inst) => !siteConfig.features.instructorExclusions.includes(inst.fullName)
            )
          }

          this.instructors = instructors.sort((a, b) => {
            if (normalize(a.lastName) < normalize(b.lastName)) {
              return -1
            } else if (normalize(a.lastName) > normalize(b.lastName)) {
              return 1
            }
            return 0
          })

          this.loadingInstructors = false
        })
        .catch((error) => {
          this.loadingInstructors = false
          console.log('error', error)
        })
        .finally(() => {
          if (siteConfig.key === 'perkins') {
            this.fetchInstructorGroups()
          }
        })
    },

    async fetchInstructorGroups() {
      try {
        const response = await axios.get(`${siteConfig.apiBaseUrl}/reports/queries/perkins/instructor-groups`)

        const groups = response.data.results

        if (groups.length) {
          groups.forEach((group) => {
            this.groupsForInstructorId[group.person_id] = this.groupsForInstructorId[group.person_id] || []
            this.groupsForInstructorId[group.person_id].push(group.group_name)
          })
        }
      } catch (e) {
        console.log('fetchInstructorGroups error', e)
      }
    },

    fetchPrograms() {
      this.loadingPrograms = true

      // const req = axios.get(`${siteConfig.legacyApiBaseUrl}/classes/allprograms`, {
      //   auth: siteConfig.legacyApiAuth
      // })

      const req = axios.get(`${siteConfig.apiBaseUrl}/classes/programs`)

      req
        .then((response) => {
          const programs = response.data

          this.programs = response.data

          this.programs.forEach((p) => {
            this.programIdsByName[p.title] = p.eventId
          })

          this.loadingPrograms = false
        })
        .catch((error) => {
          this.loadingPrograms = false
          console.log('error', error)
        })
    },
    loadedClassById(id) {
      return this.openClasses?.find((c) => c.catalogId === id)
    },
    async fetchClassById(id) {
      const response = await axios.get(`${siteConfig.apiBaseUrl}/class/${id}`)
      const classData = response.data
      const ok = classData.ok && classData.data !== 'Oh Darn' // Because the API returns 'Oh Darn' when a class is not found
      return ok ? this.formatClasses([classData.data])[0] : null
    },

    formatClasses(classes) {
      const userStore = useUserStore()
      const instructorStore = useInstructorStore()

      let todaysDateStandard = getDateStandard()
      classes.forEach((cls) => {
        cls.images = []
        cls.imageUrl = getClassImageUrl(cls.imageUrl)
        cls.itemUrl = `/classes/${cls.catalogId}`

        cls.images.push(cls.imageUrl)

        if (cls.imageUrl2) {
          cls.imageUrl2 = getClassImageUrl(cls.imageUrl2)
          cls.images.push(cls.imageUrl2)
        }

        if (cls.imageUrl3) {
          cls.imageUrl3 = getClassImageUrl(cls.imageUrl3)
          cls.images.push(cls.imageUrl3)
        }
        if (cls.imageUrl4) {
          cls.imageUrl4 = getClassImageUrl(cls.imageUrl4)
          cls.images.push(cls.imageUrl4)
        }

        cls.startDateStandard = usDateFormatToYYYYMMDD(cls.startDate)
        cls.endDateStandard = usDateFormatToYYYYMMDD(cls.endDate)
        cls.registrationCutOffDateStandard = usDateFormatToYYYYMMDD(cls.registrationCutOff)
        cls.hasStarted = todaysDateStandard > cls.startDateStandard
        cls.startsToday = todaysDateStandard === cls.startDateStandard

        if (cls.registrationCutOffDateStandard) {
          cls.isPastCutOffDate = cls.registrationCutOffDateStandard < todaysDateStandard
        } else {
          cls.isPastCutOffDate = false
        }

        cls.allowDeposit = cls.nonMemberDeposit > 0
        cls.payDepositOnly = false

        cls.ages = ''
        let ageUnitMin = cls.ageUnitMin || ''
        let ageUnitMax = cls.ageUnitMax || ''
        if (cls.minAge && cls.maxAge) {
          cls.ages += `${cls.minAge} ${ageUnitMin}`
          if (cls.maxAge >= 60) {
            if (cls.minAge < 18) {
              cls.ages += ` through adult`
            } else {
              cls.ages += `+`
            }
          } else {
            cls.ages += `-${cls.maxAge} ${ageUnitMax}`
          }
        } else if (cls.minAge && !cls.maxAge) {
          if (cls.minAge < 18) {
            cls.ages += `${cls.minAge} ${ageUnitMin}+`
          } else {
            cls.ages += `${cls.minAge} ${ageUnitMin}+`
          }
        }

        cls.ages = `Ages ${cls.ages}`

        if (siteConfig.key === 'snowfarm') {
          if (cls.ages === 'Ages 13 through adult') {
            cls.ages = 'Adults & Teens'
          } else if (cls.ages === 'Ages 18+') {
            cls.ages = 'Adults'
          }
        }

        if (siteConfig.features?.cleanDescriptionMarkup === false && cls.descr && cls.descr.includes('<')) {
          cls.description = cls.descr
        } else {
          cls.description = cleanMarkup(cls.descr)
        }

        cls.locationType = 'On-site'
        const onlineLocations = ['online', 'zoom', 'webinar', 'webex', 'google meet', 'microsoft teams']
        if (cls.location?.toLowerCase().includes('off-site')) {
          cls.locationType = 'Off-site'
        } else if (onlineLocations.includes(cls.location?.toLowerCase())) {
          cls.locationType = 'Online'
        }

        cls.originalClassDayCount = cls.classDays?.length
        cls.classDays = cls.classDays?.filter(
          (d) => usDateFormatToYYYYMMDD(d.dateFmt) >= todaysDateStandard && d.available && d.publish
        )

        cls.classDays?.forEach((d) => {
          d.dateStandard = usDateFormatToYYYYMMDD(d.dateFmt)
        })

        // XXX use dateStandard from above
        cls.classDays = cls.classDays?.sort((a, b) => {
          if (
            `${usDateFormatToYYYYMMDD(a.dateFmt)}-${a.stTime}` < `${usDateFormatToYYYYMMDD(b.dateFmt)}-${b.stTime}`
          )
            return -1
          if (
            `${usDateFormatToYYYYMMDD(a.dateFmt)}-${a.stTime}` > `${usDateFormatToYYYYMMDD(b.dateFmt)}-${b.stTime}`
          )
            return 1
          return 0
        }) || []

        let allClassDays = cls.classDays?.map((day) => ({
          day: day.classDay,
          dayOfWeek: day.dayOfWeek,
          date: day.dateFmt,
          startTime: day.startTimeFmt,
          endTime: day.endTimeFmt,
        }))
        const uniqueStartTimes = Array.from(new Set(allClassDays?.map((d) => d.startTime)))
        const uniqueEndTimes = Array.from(new Set(allClassDays?.map((d) => d.endTime)))
        const uniqueClassDates = Array.from(new Set(allClassDays?.map((d) => d.date)))
        const uniqueClassDays = Array.from(new Set(allClassDays?.map((d) => d.day)))
        // NOTE: This breaks the actual order of the class days for classes with multiple days. Unsure the consiquences of removing this for classes > 7 days.
        // .sort((a, b) => {
        //   if (daySortPos[a] < daySortPos[b]) return -1
        //   if (daySortPos[a] > daySortPos[b]) return 1
        //   return 0
        // })

        cls.uniqueClassDays = uniqueClassDays

        let instructorExclusions = []
        if (siteConfig.features.instructorExclusions && siteConfig.features.instructorExclusions.length) {
          instructorExclusions = siteConfig.features.instructorExclusions
        }

        cls.instructorSummary = ''

        if (!instructorExclusions.includes(cls.instructorName)) {
          cls.instructorSummary += cls.instructorName
        }

        if (cls.instructor2Id) {
          const instructor2 = instructorStore.instructorsById[cls.instructor2Id]
          if (instructor2 && instructor2.fullName && !instructorExclusions.includes(instructor2.fullName)) {
            if (cls.instructorSummary) {
              cls.instructorSummary += ` and ${instructor2.fullName}`
            } else {
              cls.instructorSummary += instructor2.fullName
            }
          }
        }

        cls.classDaySummary = null
        if (!siteConfig.features.hideClassDay) {
          if (cls.daySales) {
            if (uniqueClassDays.length === 1) {
              if (uniqueClassDates.length > 1) {
                cls.classDaySummary = uniqueClassDays[0] + 's'
              } else {
                cls.classDaySummary = uniqueClassDays[0]
              }
            } else {
              cls.classDaySummary = 'Various days'
            }
            // if (cls.classDays.length > 1) {
            //   cls.classDaySummary = 'Various days'
            // } else if (cls.classDays.length === 0) {
            //   cls.classDaySummary = uniqueClassDays[0]
            // }
          } else if (uniqueClassDates.length === 1) {
            cls.classDaySummary = uniqueClassDays[0]
          } else if (uniqueClassDays.length === 1) {
            if (cls.sessions > 1) {
              cls.classDaySummary = uniqueClassDays[0] + 's'
            } else {
              cls.classDaySummary = uniqueClassDays[0]
            }
          } else if (uniqueClassDays.length === 2) {
            if (cls.sessions == uniqueClassDays.length) {
              cls.classDaySummary = `${uniqueClassDays[0]} and ${uniqueClassDays[1]}`
            } else {
              cls.classDaySummary = `${uniqueClassDays[0]}s and ${uniqueClassDays[1]}s`
            }
          } else if (uniqueClassDays.length === 5) {
            cls.classDaySummary = `${uniqueClassDays[0]} – ${uniqueClassDays[uniqueClassDays.length - 1]}`
          } else if (uniqueClassDays.length > 2) {
            if (cls.sessions == uniqueClassDays.length) {
              cls.classDaySummary = uniqueClassDays.join(', ')
            } else {
              cls.classDaySummary = uniqueClassDays.map((d) => `${d}s`).join(', ')
            }
          } else {
            cls.classDaySummary = ''
          }
        }

        if (siteConfig.features.classDateOverrides && siteConfig.features.classDateOverrides[cls.code]) {
          cls.dateSummary = siteConfig.features.classDateOverrides[cls.code]
        } else {
          if (cls.classDaySummary) {
            if (cls.daySales && cls.classDays.length) {
              cls.dateSummary = `${cls.classDaySummary}, ${timestampsToDateRange(
                cls.classDays[0].dateFmt,
                cls.endDate
              )}`
            } else {
              cls.dateSummary = `${cls.classDaySummary}, ${timestampsToDateRange(cls.startDate, cls.endDate)}`
            }
          } else {
            cls.dateSummary = `${timestampsToDateRange(cls.startDate, cls.endDate)}`
          }

          if (
            !cls.daySales &&
            cls.classDaySummary &&
            cls.sessions > 1 &&
            !siteConfig.features.hideClassSessionCount
          ) {
            cls.dateSummary += ` (${cls.sessions} sessions)`
          }
        }

        if (siteConfig.features.hideClassTimes) {
          cls.timeSummary = null
        } else if (siteConfig.features.classTimeOverrides && siteConfig.features.classTimeOverrides[cls.code]) {
          cls.timeSummary = siteConfig.features.classTimeOverrides[cls.code]
        } else {
          if (
            !cls.daySales &&
            cls.classDays.length > 1 &&
            cls.classDays.length <= 3 &&
            (uniqueStartTimes.length > 1 || uniqueEndTimes.length > 1)
          ) {
            cls.timeSummary = cls.classDays
              .map((d) => `${d.classDay} ${d.startTimeFmt} - ${d.endTimeFmt}`)
              .join(', ')
          } else if (uniqueStartTimes.length > 1 || uniqueEndTimes.length > 1) {
            cls.timeSummary = 'Various times'
          } else {
            if (cls.daySales && cls.classDays.length) {
              cls.timeSummary = `${cls.classDays[0].startTimeFmt} – ${cls.classDays[0].endTimeFmt}`
            } else {
              cls.timeSummary = `${cls.startTime} – ${cls.endTime}`
            }
          }
        }

        if (cls.daySales) {
          let openSlots = 0
          cls.classDays.forEach((day) => {
            if (day.limit - day.daysSold > 0) {
              openSlots += day.limit - day.daysSold
            }
          })
          cls.classIsFull = openSlots <= 0

          if (cls.fee) {
            cls.feePerSession = parseFloat((cls.fee / cls.classDays.length).toFixed(2))
          } else {
            cls.feePerSession = 0
          }
        } else {
          cls.classIsFull = cls.seatsAvailable <= 0
        }

        const membership = userStore?.membershipsInHousehold?.find((m) => m.member_id === userStore?.account.personId)

        if (membership) {
          const discount = siteConfig.memberships?.find((c) => c.categoryId === membership.category_id)?.discounts?.['class']
          if (discount) {
            let discountAmount = discount.type === 'percent' ? cls.costNon * (discount.amount / 100) : discount.value
            cls.costMember = parseFloat((cls.costNon - discountAmount).toFixed(2))
            cls.costMemberStr = String(cls.costMember)
            cls.memberCostFmt = String(cls.costMember.toFixed(2))
            cls.memberSavings = parseFloat((cls.costNon - cls.costMember).toFixed(2))
          }
        }
        // set the price to -1 in class setup to disable adding the entire series to cart
        cls.canAddEntireSeries = cls.costMember >= 0 || cls.costNon >= 0
      })
      return classes
    },

    fetchOpenClasses() {
      const instructorStore = useInstructorStore()

      this.loadingClasses = true

      if (this.openClasses.length) {
        return
      }
      const params = new URLSearchParams()
      // Waiting for API to support this for all sites
      if (siteConfig.features.fetchSimpleClasses) {
        params.append('simple', 'true')
      }
      const req = axios.get(`${siteConfig.apiBaseUrl}/classes/open?${params}`)

      req
        .then((response) => {
          let openClasses = response.data

          if (siteConfig.features.classFilters?.semesterWhitelist) {
            openClasses = openClasses.filter((c) =>
              siteConfig.features.classFilters?.semesterWhitelist?.includes(c.semesterId)
            )
          }

          let todaysDateStandard = getDateStandard()

          openClasses = this.formatClasses(openClasses)
          openClasses = openClasses.filter((c) => !c.cancelled)

          if (siteConfig.features?.hideStartedClasses) {
            openClasses = openClasses.filter((c) => {
              if (!c.daySales) {
                return c.startDateStandard >= todaysDateStandard
              } else {
                const validDays = c.classDays.filter((d) => {
                  return d.dateStandard >= todaysDateStandard
                })
                return validDays.length > 0
              }
            })
          }

          if (siteConfig.features?.hideEndedClasses) {
            openClasses = openClasses.filter((c) => {
              return c.endDateStandard >= todaysDateStandard
            })
          }

          const programMediums = []
          const secondaryMediums = []
          const categories = []
          const semesters = []
          const levels = []
          const openClassInstructorIds = []
          const activeClassInstructorIds = []
          const openClassProgramIds = []
          const locations = []
          let sites = [] // XXX

          if (siteConfig.key === 'perkins') {
            sites = ['Collingswood', 'Moorestown']
          }

          openClasses.forEach((cls) => {
            if (cls.eventId && !openClassProgramIds.includes(cls.eventId)) {
              openClassProgramIds.push(cls.eventId)
            }

            let allMediaOk = siteConfig.features.keepAllMedia

            if (cls.medium && !programMediums.includes(cls.medium)) {
              if (cls.medium.trim() !== 'None' && (allMediaOk || (cls.medium1 && cls.medium1.trim() !== 'All Media'))) {
                programMediums.push(cls.medium)
              } else {
                cls.mediumWas = cls.medium
                cls.medium = ''
              }
            }
            if (cls.medium1 && !secondaryMediums.includes(cls.medium1)) {
              if (cls.medium1.trim() !== 'None' && (allMediaOk || cls.medium1.trim() !== 'All Media')) {
                secondaryMediums.push(cls.medium1)
              }
            }
            if (cls.medium2 && !secondaryMediums.includes(cls.medium2)) {
              if (cls.medium2.trim() !== 'None' && (allMediaOk || cls.medium2.trim() !== 'All Media')) {
                secondaryMediums.push(cls.medium2)
              }
            }

            if (cls.department && !categories.includes(cls.department)) {
              categories.push(cls.department)
            }

            if (cls.semesterName && !semesters.includes(cls.semesterName)) {
              semesters.push(cls.semesterName)
            }

            let level = cls.classLevel?.trim()
            if (level && !levels.includes(level)) {
              levels.push(level)
            }

            if (cls.location && !locations.includes(cls.location)) {
              if (!cls.location?.toLowerCase().includes('all')) {
                locations.push(cls.location)
              }
            }

            if (cls.location && siteConfig.key === 'perkins') {
              if (cls.location.indexOf('CW') === 0) {
                cls.site = 'Collingswood'
              } else if (cls.location.indexOf('MT') === 0) {
                cls.site = 'Moorestown'
              }
            }

            if (cls.instructorId && cls.instructorId !== 0) {
              if (!openClassInstructorIds.includes(cls.instructorId)) {
                if (!cls.isPastCutOffDate || siteConfig.features?.showClassesAfterCutOffDate) {
                  openClassInstructorIds.push(cls.instructorId)
                }
              }
              if (!activeClassInstructorIds.includes(cls.instructorId)) {
                activeClassInstructorIds.push(cls.instructorId)
              }
            }
            if (cls.instructor2Id && cls.instructor2Id !== 0) {
              if (!openClassInstructorIds.includes(cls.instructor2Id)) {
                if (!cls.isPastCutOffDate || siteConfig.features?.showClassesAfterCutOffDate) {
                  openClassInstructorIds.push(cls.instructor2Id)
                }
              }
              if (!activeClassInstructorIds.includes(cls.instructor2Id)) {
                activeClassInstructorIds.push(cls.instructor2Id)
              }
            }
            const keywords = cls.keywords?.split(',')?.map((k) => k.trim())
            if (keywords && keywords.length) {
              keywords.forEach((k) => {
                if (!this.keywords.includes(k)) {
                  this.keywords.push(k)
                }
              })
            }
          })

          // if (siteConfig.features.separateClassesAndWorkshops) {
          //   this.openWorkshops = openClasses.filter(c => c.division === 'workshop')
          //   this.openClasses = openClasses.filter(c => c.division === 'class')
          // } else {
          //   this.openClasses = openClasses
          // }

          if (siteConfig.features.showOpenClassesAboveClosed) {
            openClasses = openClasses.sort((a, b) => {
              if (a.isPastCutOffDate) return 1
              return -1
            })
          }

          this.openClasses = openClasses
          this.programMediums = programMediums.map((m) => m.trim()).sort()
          this.secondaryMediums = secondaryMediums.map((m) => m.trim()).sort()

          let allMediums = uniq([...this.programMediums, ...this.secondaryMediums])
            .map((m) => capitalizeFirstLetter(m))
            .sort()


          // XXX Should we also treat "All Media" as an exclusion instead of giving it special treatment?
          if (siteConfig.features?.mediumExclusions) {
            allMediums = allMediums.filter((m) => !siteConfig.features.mediumExclusions.includes(m))
          }

          this.mediums = allMediums

          if (siteConfig.features.categoryOrder) {
            const order = siteConfig.features.categoryOrder
            this.categories = categories.sort((a, b) => {
              if (order.indexOf(a) > order.indexOf(b)) return 1
              if (order.indexOf(a) < order.indexOf(b)) return -1
              return 0
            })
          } else {
            this.categories = categories.sort()
          }

          this.semesters = semesters.sort()

          if (siteConfig.features.classLevelOrder) {
            const order = siteConfig.features.classLevelOrder
            this.levels = levels.sort((a, b) => {
              if (order.indexOf(a) > order.indexOf(b)) return 1
              if (order.indexOf(a) < order.indexOf(b)) return -1
              return 0
            })
          } else {
            this.levels = levels.sort()
          }

          this.locations = locations.sort()
          this.sites = sites.sort()

          this.openClassProgramIds = openClassProgramIds
          instructorStore.updateOpenClassInstructorIds(openClassInstructorIds)
          instructorStore.updateActiveClassInstructorIds(activeClassInstructorIds)

          //sort classes by the first class start date
          if (siteConfig.key === 'pittsburgh') {
            this.openClasses = this.openClasses.sort((a, b) => {
              if (a.classDays[0]?.dateStandard < b.classDays[0]?.dateStandard) return -1
              if (a.classDays[0]?.dateStandard > b.classDays[0]?.dateStandard) return 1
              return 0
            })
          }
          this.updateMembershipPricing()
          this.loadingClasses = false
        })
        .catch((error) => {
          this.loadingClasses = false
          console.log('error', error)
        })
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useClassStore, import.meta.hot))
}