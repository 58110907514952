<script setup>
import { ref, inject, watchEffect, computed, onMounted } from 'vue'
import CartSection from './CartSection.vue'
import { useCartStore } from '@/stores/CartStore'
import { useUserStore } from '@/stores/UserStore'
import { useHeaderStore } from '@/stores/HeaderStore'
import { useCommonStore } from '@/stores/CommonStore'
import { useHeader } from '../../hooks/useHeader'
import QuickLinksWithNestedItems from './PreNav/QuickLinksWithNestedItems.vue'
import AuthBlock from './AuthBlock/index.vue'
import { useRouter, useRoute } from 'vue-router'

const siteConfig = inject('siteConfig')

const { openSubmenu, preparedMainLinks } = useHeader()

const quickLinks = ref(siteConfig.quickLinks)
const mainLinks = ref(siteConfig.mainLinks || preparedMainLinks(siteConfig))

const cartStore = useCartStore()
const headerStore = useHeaderStore()
const commonStore = useCommonStore()
const userStore = useUserStore()
const route = useRoute()
const router = useRouter()

const hideNav = computed(() => {
  return route.meta.hideNav
})

watchEffect(() => {
  if (cartStore.openedCart) {
    headerStore.updateMenuOpenState(false)
  }
})

const toggleNavigation = () => {
  headerStore.updateMenuOpenState(!headerStore.isMenuOpened)
  window.scrollTo(0, 0)
  outsideCloseCart()
}
const closeNavigation = () => {
  headerStore.updateMenuOpenState(false)
}

const outsideCloseCart = () => {
  if (commonStore.windowInnerWidth > 1280) {
    cartStore.toggleCart(false)
  }
}

onMounted(() => {
  router.isReady().then(() => {
    // console.log('route.query', route.query)
    if (route.query.cart === '1') {
      setTimeout(() => openCart(), 200)
    }
    mainLinks.value.forEach((mainLink) => {
      if (mainLink.subLinks && mainLink.subLinks.length > 0) {
        mainLink.subLinks.forEach((sublink) => {
          // console.log('sublink.route', sublink.route, route.path)
          if (sublink.route === route.path) {
            mainLink.isSelected = true
            if (commonStore.windowInnerWidth && commonStore.windowInnerWidth < 1280) {
              mainLink.openedSublinks = true
            }
          }
        })
      }
    })
  })
})
</script>

<template>
  <nav class="container large-regular 2xl:text-xl">
    <div :class="['content flex items-center justify-between gap-8', mainLinks.length > 9 && commonStore.windowInnerWidth > 1280 ? 'nav-many-items' : '']">
      <div class="store-logo">
        <a :href="siteConfig.org.website">
          <img
            class="max-h-[50px] xl:max-h-[70px] xl:max-w-[240px] min-h-[25px]"
            :src="`/sites/${siteConfig.key}/${siteConfig.org.logoFilename}`"
            :alt="`${siteConfig.org.name} logo`"
          >
        </a>
      </div>
      <div class="wrapper" v-click-outside="closeNavigation">
        <button
          class="nav-toggler"
          @click.stop="toggleNavigation"
          :class="{ opened: headerStore.isMenuOpened }"
        >
          <span></span>
          <span></span>
          <span></span>
        </button>
        <div
          class="overlay"
          v-if="headerStore.isMenuOpened && commonStore.windowInnerWidth < 1280"
          @click="headerStore.updateMenuOpenState(false)"
        />
        <nav
          class="store-navigation"
          :class="[
            { opened: headerStore.isMenuOpened },
            { 'logged-in': userStore.loggedIn },
          ]"
        >
          <div class="container" v-if="route.name && !hideNav">
            <ul class="nav-links flex-wrap">
              <template v-if="commonStore.windowInnerWidth < 1280 && quickLinks">
                <QuickLinksWithNestedItems
                  :links="quickLinks"
                  @openSubmenu="openSubmenu"
                  :routerLinkClasses="'after:!hidden'"
                  :linkClasses="'after:!hidden'"
                />
              </template>
              <QuickLinksWithNestedItems
                :links="mainLinks"
                @openSubmenu="openSubmenu"
              />
            </ul>
            <div class="column" v-if="!siteConfig.features.cartDisabled">
              <CartSection :classes="'normal-case'" />
            </div>

            <AuthBlock />
          </div>
        </nav>
      </div>
    </div>
  </nav>
</template>
