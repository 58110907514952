<script setup>
import { onMounted, computed, provide, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'

import StoreHeader from './components/StoreHeader/index.vue'
import StoreFooter from './components/StoreFooter.vue'
import ScrollToTop from './components/ScrollToTop.vue'

import AltFooter from './components/AltFooter.vue'

import { useClassStore } from '@/stores/ClassStore'
import { useUserStore } from '@/stores/UserStore'
import { useCartStore } from '@/stores/CartStore'
import { useEventStore } from '@/stores/EventStore'
import { useCommonStore } from '@/stores/CommonStore'
import { useInstructorStore } from '@/stores/InstructorStore'
import { useExhibitionStore } from '@/stores/ExhibitionsStore'
import Bend from '@/bend/BendClient'

import siteConfig from '@/config.json'
import axios from 'axios'

provide('siteConfig', siteConfig)
provide('bend', Bend)

const classStore = useClassStore()
const userStore = useUserStore()
const cartStore = useCartStore()
const eventStore = useEventStore()
const commonStore = useCommonStore()
const instructorStore = useInstructorStore()
const exhibitionStore = useExhibitionStore()
const route = useRoute()
const router = useRouter()

const ready = computed(() => {
  return route.matched && route.matched.length
})

// XXX TODO Instead of this hack, use route.meta to set a flag and check for it here?

const removeDefaultHeader = computed(() => {
  if (route.matched && route.matched.length) {
    if (
      route.matched[0].name === 'Submissions: index' ||
      route.matched[0].name === 'Purchase: index' ||
      route.matched[0].name === 'Checkout: index' ||
      route.matched[0].path === '/docs' ||
      route.matched[0].path.includes('/onboarding') ||
      route.matched[0].path.includes('/cart-add') ||
      route.matched[0].path.includes('/reports')
    ) {
      return true
    } else {
      return false
    }
  } else {
    return false
  }
})

onMounted(async () => {
  // KN: This was added because they accidentally did a mailing with a link to this 
  // preview site early on and their students started going to it and were getting
  // SSL errors. It's probably ok to disable at this point, but leaving here in case 
  // it needs to be restored
  // if (window.location.hostname === 'dev.potters.torpedo.io') {
  //   window.location = 'https://canvas.berkeleypottersstudio.org/classes'
  //   return
  // }
  await userStore.checkSession()

  if (siteConfig.modules.classes) {
    instructorStore.fetchInstructors()
    classStore.fetchOpenClasses()
    classStore.fetchPrograms()
    classStore.fetchRegistrationCounts()


    cartStore.init()

    if (siteConfig.features.preAuthEnabled) {
      userStore.fetchPreAuthorized()
    }
  }

  if (siteConfig.modules.events) {
    eventStore.fetchEvents()
  }

  if (siteConfig.modules.donations && siteConfig.features.campaigns?.enabled) {
    userStore.fetchCampaigns()
  }

  if (siteConfig.key === 'snowfarm') {
    cartStore.fetchSalesItems()
    setInterval(() => {
      cartStore.fetchSalesItems()
    }, 45000)
  }

  commonStore.updateWindowWidth(window.innerWidth)
  window.addEventListener('resize', () => {
    commonStore.updateWindowWidth(window.innerWidth)
  })

  if (siteConfig.bendConfig) {
    Bend.logIn({
      username: siteConfig.bendConfig.username,
      password: siteConfig.bendConfig.password,
    }).then(() => {
      userStore.bendLoggedIn = true
    })
  }
})

watch(
  () => userStore.loggedOut,
  (loggedOut, prevLoggedOut) => {
    if (!prevLoggedOut && loggedOut && route.meta.requireAuth) {
      // XXX does this make sense? only really necessary if you're logging out
      // and on a page that requires login. otherwise user could stay on same page.
      router.push({ name: 'Store Classes' })
    }
  }
)

watch(
  () => userStore.loggedIn,
  (loggedIn, prevLoggedIn) => {
    if (!prevLoggedIn && loggedIn) {
      if (siteConfig.features?.fetchBalanceOnLoad) {
        userStore.fetchBalance()
      }
    }
  }
)

watch(
  () => [classStore.loadingClasses], () => {
    if (!classStore.loadingClasses) {
      console.log('classes have loaded')
      cartStore.filterOutBadItems()

    }
  }
)

</script>

<template>
  <template v-if="ready">
    <div :class="siteConfig.key">
      <StoreHeader v-if="!removeDefaultHeader" />

      <RouterView />

      <StoreFooter v-if="!removeDefaultHeader" />
      <AltFooter
        v-if="removeDefaultHeader && route.matched[0].path !== '/docs'"
      />

      <ScrollToTop v-if="!removeDefaultHeader" />
    </div>
  </template>
</template>

<style lang="postcss">
#app {
  @apply min-h-screen;
}
</style>
